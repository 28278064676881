module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_US","url":"https://www.nari.ge/","site_name":"Nari Solutions"},"twitter":{"handle":"@narisolutions","site":"@narisolutions","cardType":"summary_large_image"},"titleTemplate":"%s | Nari Solutions","title":"Nari Solutions","description":"Nari Solutions is a Engineering, Design, and IT consulting company based in Tbilisi, Georgia","images":[{"url":"https://www.nari.ge/narisolutions.png","width":800,"height":600,"alt":"Og Image Alt"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"nari-solutions","short_name":"Nari Solutions","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"browser","icon":"src/assets/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9e6b0e496deab7ded3585b632050d035"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QS77B9ZM9J"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":["/preview/**"]}},
    }]
